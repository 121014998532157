import { Component, Vue } from 'vue-property-decorator';

import CtaLinkItem from './../../common/interfaces/CtaLinkItem';

import CtaButton from '@/components/CtaButton/CtaButton.vue';
import Phone from '@/assets/icons/phone.svg?inline';

export interface CtaItemType {
  Header: String;
  SecondHeader?: String;
  Button?: CtaLinkItem;
}

const CtaItemProps = Vue.extend({
  props: {
    item: Object as () => CtaItemType,
    mod: String
  },
});

@Component({ components: { CtaButton, Phone } })
export default class CtaItem extends CtaItemProps {
  get isPhone() {
    return this.item.Button && this.item.Button.Action === 'Phone';
  }
  get onePhone() {
    return this.item.Button.Link?.replace(/ /g,'.');
  }
  get twoPhones() {
    const header = this.item.SecondHeader;
    if (header) {
      const phones = header.split('| ');
      if (phones.length > 1) {
        return phones.map(phone => ({
          text: phone, 
          call: (phone.startsWith('0') || phone.startsWith('1300') ? '':'+') + phone.replace(/[^\d\s\.\+]/gi, '').trim().replace(/ /g, '.')
        }))
      }
    }
  }
  get button() {
    const btn = this.item.Button.Link
    return {
      ...this.item.Button,
      Link: btn && btn.startsWith('+1300') ? btn.replace(/\+/g, '') : btn
    }
  }
}
