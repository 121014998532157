import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import Pin from '@/assets/icons/pin-fixed.svg?inline';
import LocationViewModel from '@/common/interfaces/modules/LocationInfo';
import { ImageSizes, ImageSize } from '@/common/interfaces/SizeBreakpoints';

const LocationInfoComponentProps = Vue.extend({
  props: { location: Object as () => LocationViewModel },
});

@Component({
  components: { Pin},
})
export default class LocationInfoComponent extends LocationInfoComponentProps {
  photoSizes: ImageSizes = {
    def: new ImageSize([45, 45]),
    sizes: {},
  };
  visibleLocationInfo = false;
   
  toggleLocationInfo()
  {
     this.visibleLocationInfo = !this.visibleLocationInfo;     
  }
  closeLocationInfo()
  {
    this.visibleLocationInfo =  false;
  }
  get locationParts() {
    if (!this.location.Location) return '';

    const { PhotoDate, Location,  Coordinates} = this.location.Location;

    return [ PhotoDate, Location, Coordinates];
  }
}
