import { Component, Vue, Watch } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';

import { HAS_ANCHOR_BAR, isInBreakpoint } from '@/store/getters';
import { SET_FROM_ANCHOR } from '@/store/actions';

import VideoPlayer from '@/components/VideoPlayer/VideoPlayer.vue';
import ResponsiveImage from '@/components/ResponsiveImage/ResponsiveImage.vue';
import PhotographyInfoComponent from '@/components/PhotographyInfoComponent/PhotographyInfoComponent.vue';
import PlayOutline from '@/assets/icons/play-white.svg?inline';
import Pin from '@/assets/icons/pin-filled.svg?inline';
import Cross from '@/assets/icons/cross.svg?inline';

import { getComponentProps, openModal, closeModal, getModalId } from '@/utils/vueUtils';
import { VideoJsPlayerOptions } from 'video.js';
import SizeBreakpoints, { ImageSizes, ImageSize } from '@/common/interfaces/SizeBreakpoints';

import { HeroLargeViewModel } from './HeroModule.types';

import { getResizeImage } from '@/utils/commonUtils';
import CtaItem from '@/components/CtaItem/CtaItem.vue';

import FocusTrapDirective from '@/directives/FocusTrapDirective';
import { stripHtml } from '@/utils/stringUtils';
import LocationInfoComponent from '@/components/LocationInfoComponent/LocationInfoComponent.vue';
import { getStore } from '@/store';

const HeroProps = Vue.extend({
  props: getComponentProps<HeroLargeViewModel>({
    Title: 'Alaska',
    Image: {
      Url: getResizeImage('hike.jpg'),
      Alt: 'Hikers',
    },
    Latitude: '82.8628',
    Longitude: '135.0000',
    PhotographyInfo: {
      Photographer: {
        PhotographerName: 'Robert Swan',
        PhotographerTitle: 'Guest',
        PhotoDate: 'May 2018',
        PhotographerImage: {
          Url: getResizeImage('photographer.jpg'),
          Alt: 'Photographer',
        },
      },
      Exposure: {
        Speed: 'F/8',
        Aperture: '1/800',
        Iso: 'ISO 400',
      },
    },
    LocationInfo: {
      Location: {
        Location: '82.8628' + ' 135.0000',
        Coordinates: 'Guest',
        PhotoDate: 'May 2018'
      }
    },
    Cta: {
      Title: 'View Itineraries',
      Link: '#',
    },
    ScrollDownTitle: 'Scroll Down',
    ScrollToLink: '#main-content',
    Video: {
      Url: '',
      Type: 'video/youtube',
      Poster: '',
    },
  }),
});

@Component({
  components: {
    VideoPlayer,
    ResponsiveImage,
    PhotographyInfo: PhotographyInfoComponent,
    LocationInfo: LocationInfoComponent,
    PlayOutline,
    Pin,
    Cross,
    CtaItem,
  },
  directives: {
    'trap-focus': FocusTrapDirective,
  },
})
export default class HeroModule extends HeroProps {
  @Getter(HAS_ANCHOR_BAR) hasAnchorBar!: boolean;
  @Action(SET_FROM_ANCHOR) setLink!: (link: string) => void;

  coordinatesSlideOut: boolean = false;
  videoOptions: VideoJsPlayerOptions = {
    autoplay: true,
    loop: true,
    muted: true,
    controls: false,
    nativeControlsForTouch: false,
  };
  modalVideoOptions: VideoJsPlayerOptions = {
    autoplay: true,
    loop: false,
    muted: false,
    controls: true,
    nativeControlsForTouch: false,
  };

  @Getter(isInBreakpoint) isInBreakpoint!: (
    breakpoints: SizeBreakpoints[] | SizeBreakpoints,
  ) => boolean;
  get isTouch() {
    return this.isInBreakpoint(['xs', 'sm', 'md']);
  }

  imageSizes: ImageSizes = {
    def: new ImageSize([1920, 1080]),
    sizes: {
      sm: new ImageSize([768, 1024]),
      md: new ImageSize([992, 744]),
      lg: new ImageSize([1199, 900]),
      xl: new ImageSize([1920, 1080]),
    },
  };
  get ScrollDownTitle(){
    return this.p.ScrollDownTitle ? this.p.ScrollDownTitle : "Scroll to explore this destination"
  }
  get Video() {
    if (this.p.Video) {
      return {
        ...this.p.Video,
        Poster: this.p.Video.Poster || this.p.Image.Url,
      };
    }
  }

  get showBackground() {
    var hasFullVideo = this.p.Video && this.p.Video.Url;

    return this.p.Image && !hasFullVideo;
  }

  get photoPosition() {
    const { Latitude, Longitude } = this.p;

    if (!Latitude || !Longitude) return null;

    return `${Latitude}° S, ${Longitude}° E`;
  }

  get isShortTitle() {
    return this.p.Title.length <= 10;
  }

  get isLongTitle() {
    return stripHtml(this.p.Title).length >= 50;
  }

  get titleClass() {
    if (this.isShortTitle) {
      return 'hero__title--short';
    }
    if (this.isLongTitle) {
      return 'hero__title--long';
    } else {
      return 'hl';
    }
  }

  modalId = getModalId(this.uuid);
  openModal() {
    openModal(this.$modal, this.modalId);
    this.videoOptions.autoplay = false;
  }

  closeModal() {
    closeModal(this.$modal, this.modalId);

    this.videoOptions.autoplay = true;
  }

  scrollToLink(link: string, event: Event) {
    if (link.startsWith('#')) {
      event.preventDefault();
      this.setLink(link);
    }
  }

  get achorLink() {
    const link = this.p.Cta.Link;
    if (link?.startsWith('#')) {
      return window.location.pathname.replace(/\/$/, '') + link
    }
    return link
  }

  get heroAchorLink() {
    const link = this.p.ScrollToLink;
    if (link?.startsWith('#')) {
      return window.location.pathname.replace(/\/$/, '') + link
    }
    return link
  }

  mounted() {
    if (this.p.ScrollToLink) {
      setTimeout(() => {
        this.coordinatesSlideOut = true;
      }, 5000);
    }
  }

  get isHomePage() {
    const state = getStore().state
    return state.Meta.Analytics.page_type_name === 'NewHomePage'
  }

  hasNumberOfElevatedCtaItems(numberOfItems: number) : boolean {
    if (this.p.CtaItems == null) return false;
    return this.p.CtaItems.length == numberOfItems;
  }
}

