var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"hero module",class:[{ 'hero--anchor': _vm.hasAnchorBar },
  { 'hero--cta-elevated-1' : _vm.hasNumberOfElevatedCtaItems(1)},
  { 'hero--cta-elevated-2' : _vm.hasNumberOfElevatedCtaItems(2)},
  { 'hero--cta-elevated-3' : _vm.hasNumberOfElevatedCtaItems(3)}]},[(_vm.showBackground)?_c('ResponsiveImage',{attrs:{"imageClass":"hero__image","image":_vm.p.Image,"sizes":_vm.imageSizes,"lazy":false}}):_vm._e(),_c('div',{staticClass:"hero__overlay"}),(!_vm.showBackground)?_c('VideoPlayer',{staticClass:"hero__video",attrs:{"video":_vm.p.Video,"options":_vm.videoOptions,"noAnalytics":true}}):_vm._e(),_c('div',{staticClass:"hero__lockup",class:{ 'hero__lockup--only-title': !_vm.photoPosition && !_vm.p.ScrollToLink }},[_c('div',{staticClass:"hero__lockup-top",class:{'hero__lockup-top--long': _vm.isLongTitle}},[(_vm.p.Locations)?_c('div',{staticClass:"text-center push-v--mobile--xx-small push-v--tablet--x-small"},[_c('Pin',{staticClass:"hero__pin-icon"}),_c('p',{staticClass:"hero__lockup-locations h5 color--white"},[_vm._v(_vm._s(_vm.p.Locations.join(', ')))])],1):_vm._e(),_c('div',{staticClass:"hero__title-container push-v--mobile--small",class:{
          'hero__title-container--narrow': _vm.p.FullVideo,
          'hero__title-container--short': _vm.isShortTitle,
          'hero__title-container--long': _vm.isLongTitle,
          'hero__title-container--has-cta': _vm.p.Cta,
        }},[(!_vm.isHomePage)?_c('h1',{staticClass:"hero__title hm color--white",class:_vm.titleClass,attrs:{"data-epi-edit":"Title"},domProps:{"innerHTML":_vm._s(_vm.p.Title)}}):_c('h2',{staticClass:"hero__title hm color--white",class:_vm.titleClass,attrs:{"data-epi-edit":"Title"},domProps:{"innerHTML":_vm._s(_vm.p.Title)}})]),(_vm.p.Cta || _vm.p.FullVideo)?_c('div',{staticClass:"hero__cta-wrapper push-v--mobile--x",class:{ 'hero__cta-wrapper--two-cta': _vm.p.FullVideo }},[(_vm.p.FullVideo)?_c('a',{staticClass:"hero__cta button button--ghost",attrs:{"href":"#"},on:{"click":function($event){return _vm.openModal()}}},[_vm._v("\n          Watch video\n          "),_c('PlayOutline',{staticClass:"button__icon button__icon--right button__icon--small"})],1):_vm._e(),(_vm.p.Cta)?_c('a',{directives:[{name:"segment",rawName:"v-segment:moduleClicked",arg:"moduleClicked"},{name:"segment",rawName:"v-segment:params",value:({position: 1}),expression:"{position: 1}",arg:"params"}],staticClass:"hero__cta button",class:{ 'button--white': _vm.p.Cta },style:({ visibility: _vm.p.Cta ? 'visible' : 'hidden' }),attrs:{"href":_vm.achorLink},on:{"click":function($event){return _vm.scrollToLink(_vm.p.Cta.Link, $event)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.scrollToLink(_vm.p.Cta.Link, $event)}}},[_vm._v(_vm._s(_vm.p.Cta.Title))]):_vm._e()]):_vm._e()]),(_vm.p.ScrollToLink)?_c('div',{staticClass:"hero__lockup-bottom"},[_c('p',{staticClass:"hero__coordinates text--subheading text-center push-v--mobile--small color--white"},[_c('span',{staticClass:"hero__coordinates-replacement"},[_vm._v("\n          "+_vm._s(_vm.ScrollDownTitle)+"\n          ")])]),_c('a',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
          el: _vm.p.ScrollToLink,
          duration: 500,
          easing: 'linear',
          offset: -65,
        }),expression:"{\n          el: p.ScrollToLink,\n          duration: 500,\n          easing: 'linear',\n          offset: -65,\n        }"}],staticClass:"hero__scroll",style:({ visibility: _vm.p.ScrollToLink ? 'visible' : 'hidden' }),attrs:{"href":_vm.heroAchorLink,"aria-label":"scroll down button"},on:{"click":function($event){return _vm.scrollToLink(_vm.p.ScrollToLink, $event)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.scrollToLink(_vm.p.ScrollToLink, $event)}}},[_vm._m(0)])]):_vm._e()]),_c('LocationInfo',{attrs:{"location":_vm.p.LocationInfo}}),(_vm.p.PhotographyInfo)?_c('PhotographyInfo',{attrs:{"photography":_vm.p.PhotographyInfo}}):_vm._e(),[(_vm.p.FullVideo)?_c('modal',{directives:[{name:"trap-focus",rawName:"v-trap-focus"}],attrs:{"name":_vm.modalId,"adaptive":true,"width":"100%","height":"100%"},on:{"closed":_vm.closeModal}},[_c('button',{staticClass:"hero__close cta-close",on:{"click":function($event){return _vm.closeModal()}}},[_c('span',{staticClass:"text--subheading color--white push-h--desktop--right--xx-small"},[_vm._v("Close")]),_c('Cross',{staticClass:"hero__close-icon"})],1),_c('div',{staticClass:"modal--video-frame"},[_c('div',{staticClass:"modal--video-frame--inner"},[_c('VideoPlayer',{staticClass:"hero__video-player",attrs:{"options":_vm.modalVideoOptions,"video":_vm.p.FullVideo,"modalAutoPlay":_vm.modalVideoOptions.autoplay}})],1)])]):_vm._e()],(_vm.p.CtaItems)?_c('div',{staticClass:"hero-ribbon__lockup",class:("hero-ribbon__wrapper--" + (_vm.p.CtaItems.length) + "-columns-test")},[_c('div',{staticClass:"hero-ribbon__wrapper",class:("hero-ribbon__wrapper--" + (_vm.p.CtaItems.length) + "-columns")},_vm._l((_vm.p.CtaItems),function(item,index){return _c('CtaItem',{key:index,attrs:{"item":item}})}),1)]):_vm._e()],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"hero__circle",attrs:{"aria-hidden":"true","focusable":"false"}},[_c('div',{staticClass:"hero__halfclip"},[_c('div',{staticClass:"hero__halfcircle hero__halfcircle--clipped"})]),_c('div',{staticClass:"hero__halfcircle hero__halfcircle--fixed"})])}]

export { render, staticRenderFns }