import { DirectiveOptions } from 'vue';

class FocusTrapper {
  static trappedElement: FocusTrapperElement;

  static startFocusTrap(el: HTMLElement) {
    if (this.trappedElement) this.trappedElement.removeEventListeners();
    this.trappedElement = new FocusTrapperElement(el);
  }

  static killFocusTrap(el: HTMLElement) {
    if (this.trappedElement) this.trappedElement.removeEventListeners();
    this.trappedElement = null;
  }
}

class FocusTrapperElement {
  rootEl: HTMLElement;
  protected clickEventListener: EventListener;

  constructor(el: HTMLElement) {
    this.rootEl = el;
    this.clickEventListener = (event) => this.onKeydown(event);
    this.addEventListeners();
    this.setFocusToFirstNode();
  }

  FOCUSABLE_ELEMENTS: Array<string> = [
    'a[href]',
    'area[href]',
    'input:not([disabled]):not([type="hidden"]):not([aria-hidden])',
    'select:not([disabled]):not([aria-hidden])',
    'textarea:not([disabled]):not([aria-hidden])',
    'button:not([disabled]):not([aria-hidden])',
    'iframe',
    'object',
    'embed',
    '[contenteditable]',
    '[tabindex]:not([tabindex^="-"])',
  ];

  getFocusableNodes(): HTMLElement[] {
    const nodes = <HTMLScriptElement[]>(
      (<any>this.rootEl.querySelectorAll(this.FOCUSABLE_ELEMENTS.join(', ')))
    );
    return Array(...nodes);
  }

  setFocusToFirstNode(): void {
    const focusableNodes = this.getFocusableNodes();
    if (focusableNodes.length) focusableNodes[0].focus();
  }

  maintainFocus(event: KeyboardEvent) {
    const focusableNodes = this.getFocusableNodes();

    if (!this.rootEl.contains(document.activeElement)) {
      focusableNodes[0].focus();
    } else {
      const focusedItemIndex = focusableNodes.indexOf(<HTMLElement>document.activeElement);

      if (event.shiftKey && focusedItemIndex === 0) {
        focusableNodes[focusableNodes.length - 1].focus();
        event.preventDefault();
      }

      if (!event.shiftKey && focusedItemIndex === focusableNodes.length - 1) {
        focusableNodes[0].focus();
        event.preventDefault();
      }
    }
  }

  addEventListeners() {
    document.addEventListener('keydown', this.clickEventListener);
  }

  removeEventListeners() {
    document.removeEventListener('keydown', this.clickEventListener);
  }

  onKeydown(event: Event) {
    if ((<KeyboardEvent>event).keyCode === 9) this.maintainFocus(<KeyboardEvent>event);
  }
}

const FocusTrapDirective: DirectiveOptions = {
  inserted(el) {
    FocusTrapper.startFocusTrap(el);
  },
  unbind(el) {
    FocusTrapper.killFocusTrap(el);
  },
};

export default FocusTrapDirective;
