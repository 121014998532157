import Vue from 'vue';
import { Component } from 'vue-property-decorator';

import Lens from '@/assets/icons/lens.svg?inline';
import LindbladEye from '@/assets/icons/shutter.svg?inline';
import ResponsiveImage from '@/components/ResponsiveImage/ResponsiveImage.vue';

import PhotographyViewModel from '@/common/interfaces/modules/PhotographyInfo';
import { ImageSizes, ImageSize } from '@/common/interfaces/SizeBreakpoints';

const PhotographyInfoComponentProps = Vue.extend({
  props: { photography: Object as () => PhotographyViewModel },
});

@Component({
  components: { Lens, LindbladEye, ResponsiveImage },
})
export default class PhotographyInfoComponent extends PhotographyInfoComponentProps {
  photoSizes: ImageSizes = {
    def: new ImageSize([45, 45]),
    sizes: {},
  };

   openPhotoCredits = false;

  togglePhotoCredits() {
    this.openPhotoCredits = !this.openPhotoCredits;
  }

  closePhotoCredits() {
    this.openPhotoCredits = false;
  }

  get exposure() {
    return this.photography.Exposure;
  }

  get photo() {
    return this.photography.Photographer;
  }

  get exposureInfo() {
    if (!this.exposure) return '';

    const { Iso, Speed, Aperture } = this.exposure;

    if (!Iso && !Speed && !Aperture) {
      return ''
    }

    var info = "";

    if(Speed)
    info += `F/${Speed}`;

    if(Aperture)
      if(info)
        info+=` | 1/${Aperture}`;
      else
        info =`1/${Aperture}`;

    if(Iso)
      if(info)
        info+=` | ISO ${Iso}`;
      else
        info =`ISO ${Iso}`;
    
    return info;
  }

  get photographerInfo()
  {
    var photographer = null;
    
    if(this.photo.PhotographerName)
      photographer = this.photo.PhotographerName;

    if(this.photo.PhotographerTitle)    
      if(photographer)
        photographer += `, ${this.photo.PhotographerTitle}`    
      else      
        photographer = this.photo.PhotographerTitle;

    return photographer
  }

  get photographyParts() {  

    return [this.exposureInfo, this.photographerInfo];
  }
}
